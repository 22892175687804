import previous_nav from './previous_nav';

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Products',
    icon: 'PackageIcon',
    children: [
      {
        title: 'SKU Master',
        route: 'products.index',
      },
      {
        title: 'Categories',
        route: 'item.index',
      },
      // {
      //   title: 'Variations',
      //   route: 'variation.index',
      // },
      {
        title: 'Brand',
        route: 'brand.index',
      },
      {
        title: 'Unit',
        route: 'unit.index',
      },
      // {
      //   title: 'Base Unit',
      //   route: 'baseunit',
      // },
      {
        title: 'SKU Adjustment',
        route: 'skuadjustment',
      },
      {
        title: 'Import/Export SKU Toko',
        route: 'import-export-sku-toko',
      },
    ],
  },
  {
    title: 'Subscription',
    route: 'merchant.index',
    icon: 'CalendarIcon',
  },
  {
    title: 'Affiliate',
    icon: 'UsersIcon',
    children: [
      {
        title: 'User',
        route: 'sales.index',
      },
      {
        title: 'Withdraw',
        route: 'withdraw.index',
      },
    ],
  },
  {
    title: 'Bonus Affiliate',
    route: 'bonus.index',
    icon: 'GiftIcon',
  },
  {
    title: 'Ads Management',
    route: 'image.index',
    icon: 'ImageIcon',
  },
  {
    title: 'Customer Service',
    route: 'ticket.index',
    icon: 'HeadphonesIcon',
    tag: '0',
    tagVariant: 'light-danger',
    refName: 'notificationTicket',
  },
  ...previous_nav,
];
