export default [
  {
    title: 'Toko',
    route: 'toko.index',
  },
  {
    title: 'Cabang',
    route: 'cabang.index',
  },
  {
    title: 'Gudang',
    route: 'gudang.index',
  },
  {
    title: 'Supplier',
    route: 'supplier.index',
  },
  {
    title: 'Customer',
    route: 'customer.index',
  },
  {
    title: 'Berlangganan',
    route: 'subscription.index',
  },
  {
    title: 'Users',
    route: 'users.index',
  },
  {
    title: 'Master Produk',
    route: 'master.product.index',
  },
  {
    title: 'Bank',
    route: 'master-bank.index',
  },
  {
    title: 'Career',
    route: 'career.index',
  },
  {
    title: 'Contact Us',
    route: 'contact_us.index',
  },
  {
    title: 'Admin Users',
    route: 'master-admin.index',
  },
];
